$('#mobile', '.login-form').on('keypress paste', function(e) {
	ensureMobileOnlyInput(e, this)
})
$('.login-form').on('submit', function() {
	$('#flash').hide()
	return validateLoginForm(this)
}).on('change', function(e) {
	return validateLoginFormField(e.target)
})

function validateMobileNumber(number) {
	// if (number.length != 8) return false
	// return /^[49]+[0-9]+$/.test(number)
	return true
}

function validateLoginForm(form) {
	var validates = true,
			fieldsToValidate = '#mobile, #code'
	$(fieldsToValidate).each(function() {
		if (!validateLoginFormField(this)) validates = false
	})
	return validates
}

function validateLoginFormField(field) {
	var field = $(field),
			fieldVal = field.val(),
			formGroup = $(field.closest('.form-group'))
	field.removeClass('form-control-danger')
	formGroup.removeClass('has-danger')
	var validates = true
	if (fieldVal == '') {
		validates = false
	} else if (field.attr('id') == 'mobile') {
		if (!validateMobileNumber(fieldVal)) validates = false
	}
	if (!validates) {
		field.addClass('form-control-danger')
		formGroup.addClass('has-danger')
	}
	return validates
}

function ensureMobileOnlyInput(e, input) {
	// TODO: Improve this?
	if (e.which == 8 || e.keyCode == 8 || e.which == 9 || e.keyCode == 9 || e.which == 13 || e.keyCode == 13) return
	var value = (e.type == 'paste' ? e.originalEvent.clipboardData.getData('text') : String.fromCharCode(e.which)),
			isNumber = !isNaN(parseFloat(value)) && isFinite(value)
	if (!isNumber) {
		e.preventDefault()
	} else {
		// if ((input.value.length == 0 && value.length > 1) || ((input.selectionStart == 0 && value.length >= 8))) {
		// 	var mobileReg = /^[49]+[0-9]+$/
		// 	if (!mobileReg.test(value)) e.preventDefault()
		// } else if (input.value.length == 0 && value != '4' && value != '9') {
		// 	e.preventDefault()
		// }
	}
}
